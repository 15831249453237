import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import ConfNavbar from "./ConfNavbar";
import CaptainLogo from "../assets/CaptainLogo.png";
import GDriveLogo from "../assets/GoogleDriveLogo.png";
import PowerpointLogo from "../assets/PowerpointLogo.png";
import "../styles/resourcesStyles.css";

type Props = {};

export default function Resources({}: Props) {
  return (
    <>
      <div className="confBg">
        <ConfNavbar />
      </div>
      <Container className="mb-5">
        <h1 className="title d-flex justify-content-center mt-5 mb-4">
          CAPTAIN RESOURCES
        </h1>
        <div className="survey">
          <p
            className="registrationTitle d-flex justify-content-center"
            id="resourceTitle"
          >
            CAPTAIN 2022 SUMMIT RESOURCES
          </p>
          <Row className="d-flex justify-content-center">
            <img
              src={PowerpointLogo}
              alt="Powerpoint Logo"
              title="PowerPoint Logo"
              className="powerPointLogo"
            />
          </Row>
          <Row className="d-flex justify-content-center">
            <img
              src={CaptainLogo}
              alt="Captain Logo"
              title="CAPTAIN Logo"
              className="captainLogo"
            />
          </Row>
          <Row className="d-flex justify-content-center">
            <img
              src={GDriveLogo}
              alt="Google Drive Logo"
              title="Google Drive Logo"
              className="captainLogo"
            />
          </Row>
          <Row className="d-flex justify-content-center">
            {/* <img src={GDriveLogo} alt="" /> */}
            <p className="resourceText">
              CAPTAIN Resources are hosted on Google Drive. Click the link below
              to view our various resources such as an agenda of the conference
              and information about our mentoring sessions!
            </p>
          </Row>
          <Row>
            <a
              href="https://drive.google.com/drive/folders/14M7U0wm6g4tsyudHM7NX_G2-JF2AmK8U?usp=sharing"
              target="_blank"
              rel="noreferrer"
            >
              <button className="gradientBtn">View Resources</button>
            </a>
          </Row>
        </div>
      </Container>
    </>
  );
}
