import React, { useState, useEffect } from "react";
import Dropdown from "react-bootstrap/Dropdown";
import Form from "react-bootstrap/Form";

type Props = {
  properties: {
    label: string;
    labelSpan?: string;
    paymentWarning: string;
    buttonLabel: string;
    attendee?: string;
    selectState: string[];
    stateSetter: React.Dispatch<React.SetStateAction<string[]>>;
    priceValue: number;
    setPrice: React.Dispatch<React.SetStateAction<number>>;
    checkedStates: boolean[];
    checkedSetters: React.Dispatch<React.SetStateAction<boolean>>[];
  };
};

const multiDropOptions = [
  {
    value: 1,
    label: "Day 1 Boot Camp (Required for all first-year cadre) - $50",
    price: 50,
  },
  {
    value: 2,
    label:
      "Day 1 Workshop 1: Classroom Pivotal Response Teaching (CPRT) (can select only 1 workshop) - $50",
    price: 50,
  },
  {
    value: 2,
    label:
      "Day 1 Workshop 2 Supporting English Learners with Autism (can select only 1 workshop) - $50",
    price: 50,
  },
  // {
  //   value: 2,
  //   label: "Day 1 Mentor Orientation - No Cost - May not select a workshop",
  //   price: 0
  // },
  {
    value: 3,
    label: "Day 2 CAPTAIN 2022 Summit Required for ALL - $150",
    price: 150,
  },
];

export default function EventDropdown(props: Props) {
  const [optionValues, setValues] = useState<string[]>([]);
  let checkedStates = props.properties.checkedStates;
  let checkedSetters = props.properties.checkedSetters;

  useEffect(() => {
    if (
      props.properties.attendee === "First Year Newly Nominated CAPTAIN Cadre"
    ) {
      props.properties.stateSetter([
        "Day 2 CAPTAIN 2022 Summit Required for ALL - $150",
        "Day 1 Boot Camp (Required for all first-year cadre) - $50",
      ]);
      setValues(["1"]);
      props.properties.checkedSetters[0](true);
      props.properties.checkedSetters[1](false);
      props.properties.checkedSetters[2](false);
      props.properties.setPrice(200);
    } else {
      // Default Value
      props.properties.stateSetter([
        "Day 2 CAPTAIN 2022 Summit Required for ALL - $150",
      ]);
      setValues([]);
      props.properties.checkedSetters[0](false);
      props.properties.checkedSetters[1](false);
      props.properties.checkedSetters[2](false);
      // props.properties.checkedSetters[3](false);
      props.properties.setPrice(150);
    }
  }, [props.properties.attendee]);

  const handleCheckbox = (
    element: any,
    stateToSave: React.Dispatch<React.SetStateAction<string[]>>,
    state: string[]
  ) => {
    const textToSave = element.target.nextSibling.innerText;
    if (state.includes(textToSave)) {
      stateToSave((prevState) => {
        return prevState.filter((option) => option !== textToSave);
      });
    } else if (!state.includes(textToSave)) {
      stateToSave((prevState) => [...prevState, textToSave]);
    }
  };

  const checkboxToggle = (event: any, price: number, index: number) => {
    const selectedValue = event.target.value;
    if (!optionValues.includes(selectedValue)) {
      setValues((prevState) => [...prevState, selectedValue]);
      props.properties.setPrice((prevPrice) => (prevPrice += price));
    } else if (optionValues.includes(selectedValue)) {
      setValues((prevState) =>
        prevState.filter((value) => value !== selectedValue)
      );
      props.properties.setPrice((prevPrice) => prevPrice - price);
    }
    checkedSetters[index](!checkedStates[index]);
  };

  const formCheckbox = (
    option: { value: number; label: string; price: number },
    index: number,
    isDisablable?: boolean,
    isNewCadre?: boolean
  ) => {
    let form = (
      <Form.Check
        style={
          option.value === 2
            ? { display: "none" }
            : { display: "block" }
        }
        key={index}
        value={option.value}
        type="checkbox"
        id={`eventCheck${index}`}
        label={option.label}
        checked={checkedStates[index]}
        disabled={
          isNewCadre
            ? true
            : isDisablable
            ? !props.properties.selectState.includes(option.label) &&
              optionValues.includes(option.value.toString())
            : false
        }
        onChange={(e: any) => {
          handleCheckbox(
            e,
            props.properties.stateSetter,
            props.properties.selectState
          );
          checkboxToggle(e, option.price, index);
        }}
      />
    );
    if (option.price === 150) {
      form = (
        <Form.Check
          key={index}
          value={option.value}
          type="checkbox"
          id={`eventCheck${index}`}
          label={option.label}
          disabled={true}
          checked={true}
          onChange={(e: any) => {
            handleCheckbox(
              e,
              props.properties.stateSetter,
              props.properties.selectState
            );
            checkboxToggle(e, option.price, index);
          }}
        />
      );
    }
    return form;
  };

  return (
    <>
      <Form.Label className="formTitle" htmlFor="eventRegistration">
        {props.properties.label}
        {props.properties.labelSpan ? (
          <span> {props.properties.labelSpan}</span>
        ) : null}
        {<span className="redText">{props.properties.paymentWarning}</span>}
      </Form.Label>
      <Dropdown className="registerDropdown">
        <Dropdown.Toggle className="py-2">
          {props.properties.selectState.length === 1 ? (
            <span className="darkText">
              1 event selected - ${props.properties.priceValue} Total
            </span>
          ) : (
            <span className="darkText">
              {`${props.properties.selectState.length} events selected`} - $
              {props.properties.priceValue} Total
            </span>
          )}
        </Dropdown.Toggle>
        <Dropdown.Menu className="p-3 pt-2 pb-2">
          {props.properties.attendee ===
          "First Year Newly Nominated CAPTAIN Cadre"
            ? multiDropOptions
                .filter((option) => option.value !== 2)
                .map(
                  (
                    option: { value: number; label: string; price: number },
                    index: number
                  ) => {
                    return formCheckbox(option, index, true, true);
                  }
                )
            : props.properties.attendee === "Returning CAPTAIN Cadre"
            ? multiDropOptions
                .filter((option, index) => ![0].includes(index))
                .map(
                  (
                    option: { value: number; label: string; price: number },
                    index: number
                  ) => {
                    return formCheckbox(option, index + 1, true);
                  }
                )
            : props.properties.attendee === "RIL/CAPTAIN Leadership Team Member"
            ? multiDropOptions
                .filter((option, index) => ![0, 1, 2].includes(index))
                .map(
                  (
                    option: { value: number; label: string; price: number },
                    index: number
                  ) => {
                    return formCheckbox(option, index, true);
                  }
                )
            : multiDropOptions.map(
                (
                  option: { value: number; label: string; price: number },
                  index: number
                ) => {
                  return formCheckbox(option, index, true);
                }
              )}
        </Dropdown.Menu>
      </Dropdown>
    </>
  );
}
