import React, { Component } from "react";

const Footer: React.FC = (): JSX.Element => {
  return (
    <div className="container-fluid footerBg">
      <div className="container">
        <div className="footer">
          <img
            className="d-flex justify-content-start"
            src={require("../assets/Logo.png")}
            alt=""
          />
        </div>
      </div>
    </div>
  );
};

export default Footer;
