import Transaction from "../models/transaction";

const url: string = process.env.REACT_APP_API_URL!;

// const GetAllSessions = async () => {
//     return fetch(`${url}sessions/GetSessionsBySearchParams`,{
//         method:"POST",
//         headers: {
//             "Content-Type": "application/json"
//         },
//         body: JSON.stringify({
//             "ConfId": 12,
//             "SearchTerm": "",
//             "IsPublic": true
//         })
//     })
//     .then(response => response.json())
//     .then(data => data)
// }

export const GetAllSpeakers = async (confId: number) => {
    return fetch(`${url}api/users/GetPresentersByConfId/${confId}`, {
        headers: {
            Accept: "application/json"
        }
    })
        .then(response => response.json())
}

export const saveRegistration = async (data: FormData, confId: number) => {
    return await fetch(`${url}v2/transaction/Register/${confId}`, {
        method: 'POST',
        body: data
    }).then(response => response.json())
}
export const UpdatePayment = async (TransactionId: number, CreditId: string) => {
    return await fetch(`${url}v2/transaction/UpdatePayment/${TransactionId}/${CreditId}`, {
        method: 'GET',
        headers: {
            Accept: "application/json"
        }
    })
        .then(response => response.json())
        .then(data => data)
}

export const CheckGuid = async (guid: any) => {
    return await fetch(`${url}v2/user/check/${guid}`)
        .then(response => response.json())
        .then(data => data);
}

export const ResetPassword = async (userId: number, password: string) => {
    return await fetch(`${url}v2/user/reset`, {
        method: 'POST',
        headers: {
            'Content-Type': "application/json"
        },
        body: JSON.stringify({
            UserId: userId,
            Password: password
        })
    })
}

export const ForgotPassword = async (email: string) => {
    return await fetch(`${url}v2/user/forgot/${email}/20`, {
        method: 'POST',
    })
}