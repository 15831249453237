import React from "react";
import ConfNavbar from "./ConfNavbar";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import Marriott from "../assets/MarriottRiverSide.png";
import Hampton from "../assets/HamptonInn.jpg";
import Hyatt from "../assets/HyattPlace.png";
import "../styles/bookingStyles.css";
import { TitleUpdate } from "../services/TitleUpdater";
type Props = {};

export default function Booking({}: Props) {
  TitleUpdate("CAPTAIN Summit - Booking");
  return (
    <div>
      <div className="confBg">
        <ConfNavbar />
      </div>
      <h1 className="title d-flex justify-content-center mb-4 mt-5">
        BOOK YOUR STAY
      </h1>
      <Container>
        {/* Marriott At Riverside */}
        <Row className="hotelBox my-2">
          <Col lg={6} sm={12}>
            <img
              src={Marriott}
              className="reservationImage"
              alt="Marriott at Riverside"
              title="Marriott Front Entrance"
            />
          </Col>
          <Col lg={6} sm={12} className="p-0">
            <div className="hotelInfo">
              <div>
                <h3 className="surveyTitle mb-0">
                  Marriott Riverside at Convention Center
                </h3>
              </div>
              <div>
                <p className="hotelAddress smallText mb-0">
                  3400 Market Street Riverside, CA 92501
                  <li className="bulletPoint mx-2 my-auto"></li>
                  <span>(951) 784-8000</span>
                </p>
              </div>
              <div>
                <strong className="hotelDistanceText smallText">
                  Located on Riverside Convention Center property - just a quick
                  walk across the lawn
                </strong>
              </div>
            </div>
            <hr className="my-2" />
            <div className="hotelBookingInfo">
              <p className="smallText my-0">
                <strong>Call Reservations:</strong> (951) 784-8000, option 1 for
                reservations and 1 for individual reservations or{" "}
                <strong>Booking Link: </strong>
                <span>
                  <a
                    href="https://www.marriott.com/events/start.mi?id=1647040337837&key=GRP"
                    target="_blank"
                  >
                    Book your group rate for CAPTAIN SUMMIT
                  </a>
                </span>
              </p>
              <Row className="my-2">
                <Col className="hotelPrice p-0">
                  <strong className="my-1">$179/Day + tax</strong>
                  <strong className="mb-0">Parking is $20/Day</strong>
                </Col>
              </Row>
              <p className="my-0 hotelCutoff">
                <strong>Cut Off Date:</strong> Reservations must be made at this
                rate <strong>on or before Friday, October 14, 2022</strong>
              </p>
            </div>
            <Button
              className="gradientBtn mb-0"
              target="_blank"
              href="https://www.marriott.com/events/start.mi?id=1647040337837&key=GRP"
            >
              RESERVE ROOM
            </Button>
          </Col>
        </Row>
        {/* Hampton Inn & Suites */}
        <Row className="hotelBox my-5">
          <Col lg={6} sm={12}>
            <img
              src={Hampton}
              className="reservationImage"
              alt="Hampton Inn at Riverside"
              title="Hampton Inn & Suites"
            />
          </Col>
          <Col lg={6} sm={12} className="p-0">
            <div className="hotelInfo">
              <div>
                <h3 className="surveyTitle mb-0">
                  Hampton Inn Riverside Downtown
                </h3>
              </div>
              <div>
                <p className="hotelAddress smallText mb-0">
                  3505 Market Street Riverside, CA 92501
                  <li className="bulletPoint mx-2 my-auto"></li>
                  <span>(951) 788-5000</span>
                </p>
              </div>
              <div>
                <strong className="hotelDistanceText smallText">
                  A 5-minute walk from Riverside Convention Center
                </strong>
              </div>
            </div>
            <hr className="my-2" />
            <div className="hotelBookingInfo">
              <p className="smallText my-0">
                <strong>Booking Link: </strong>
                <span>
                  <a href="https://group.hamptoninn.com/mgpuec" target="_blank">
                    Book your group rate for CAPTAIN SUMMIT
                  </a>
                </span>
              </p>
              <Row className="my-2">
                <Col className="hotelPrice p-0">
                  <strong className="my-1">
                    2 Queens / 1 King: $189/Day + tax
                  </strong>
                  <strong className="mb-0">Parking is $23/Day</strong>
                </Col>
              </Row>
                <p className="m-0">
                  <strong>Rate includes:</strong> free hot breakfast; Free
                  high-speed and wireless internet
                </p>
              <p className="my-0 hotelCutoff">
                <strong>Cut Off Date:</strong> Reservations must be made at this
                rate{" "}
                <strong>
                  on or before 3:00 PM PST on Saturday, October 1, 2022
                </strong>
              </p>
            </div>
            <Button
              className="gradientBtn"
              target="_blank"
              href="https://group.hamptoninn.com/mgpuec"
            >
              RESERVE ROOM
            </Button>
          </Col>
        </Row>
        {/* Hyatt Place Riverside Downtown */}
        <Row className="hotelBox my-5">
          <Col lg={6} sm={12}>
            <img
              src={Hyatt}
              className="reservationImage"
              alt="Hyatt Place Riverside/Downtown"
              title="Hyatt Place"
            />
          </Col>
          <Col lg={6} sm={12} className="p-0">
            <div className="hotelInfo">
              <div>
                <h3 className="surveyTitle mb-0">
                  Hyatt Place Riverside/Downtown
                </h3>
              </div>
              <div>
                <p className="hotelAddress smallText mb-0">
                  3500 Market Street Riverside, CA 92501
                  <li className="bulletPoint mx-2 my-auto"></li>
                  <span>(951) 321-3500</span>
                </p>
              </div>
              <div>
                <strong className="hotelDistanceText smallText">
                  A 3-minute walk from Riverside Convention Center
                </strong>
              </div>
            </div>
            <hr className="my-2" />
            <div className="hotelBookingInfo">
              <p className="smallText my-0">
                Use the booking link to enter your arrival and departure dates.
                <br />
                Click check availability and your group rate will be populated.
                <br />
                If you have any challenges or need to book an additional night outside your group’s block, please <strong>Email:</strong> <a href="mailto:karla.pierre@hyatt.com">karla.pierre@hyatt.com</a> with your requested additional dates
                <br />
                <strong>Booking Link: </strong>
                <span>
                  <a
                    href="https://www.hyatt.com/en-US/hotel/california/hyatt-place-riverside-downtown/ralzr?corp_id=G-CAPX"
                    target="_blank"
                  >
                    Book your group rate for CAPTAIN SUMMIT
                  </a>
                </span>
                <br />
                <strong>Or Call Reservations:</strong>
                1-888-492-8847 and mention <strong>Group Code: G-CAPX</strong>
                <br />
              </p>
              <Row className="my-2">
                <Col className="hotelPrice p-0">
                  <strong className="my-1">
                    $149/day Plus 13.195% Tax for 1 King Bed
                  </strong>
                  <strong className="my-1">
                    $159/day Plus 13.195% for 2 Queens
                  </strong>
                  <strong className="mb-0">Parking is $16/Day</strong>
                </Col>
              </Row>
              <p className="m-0 smallText">
                <strong className="smallText">Rate includes:</strong> Full
                American Breakfast, Free Wi-Fi; 72 Hour Cancellation Policy
              </p>
              <p className="my-0 hotelCutoff">
                <strong className="smallText">Cut Off Date:</strong>{" "}
                Reservations must be made at this special rate{" "}
                <strong className="smallText">
                  on or before Wednesday, October 19, 2022.
                </strong>
                Any reservations booked after this date is subject to
                availability and there is no guarantee that the discounted rate
                will be available.
              </p>
            </div>
            <Button
              className="gradientBtn"
              target="_blank"
              href="https://www.hyatt.com/en-US/hotel/california/hyatt-place-riverside-downtown/ralzr?corp_id=G-CAPX"
            >
              RESERVE ROOM
            </Button>
          </Col>
        </Row>
      </Container>
    </div>
  );
}
