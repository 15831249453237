import React, { useState } from "react";
import Row from "react-bootstrap/row";
import Col from "react-bootstrap/Col";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import "../styles/speakersStyles.css";

type Props = {
  SpeakerImg: string;
  Bio?: string;
  Name: string;
  Title: string;
  BoldTitle?: string;
};

export default function SpeakerCard(props: Props) {
  const [show, setShow] = useState(false);
  const handleModal = () => {
    setShow(!show);
  };

  return (
    <>
      <Col lg={3} md={6} sm={12} className="p-0">
        <div onClick={handleModal} className="speaker">
          <img
            src={props.SpeakerImg}
            alt={props.Name}
            title={props.Name}
            className="speakerImg my-2"
          />
          <h5 className="speakerName mb-0">{props.Name}</h5>
          {props.BoldTitle ? (
            <div className="boldSpeakerTitleBox">
              <strong>{props.BoldTitle}</strong>
            </div>
          ) : null}
          <p className="speakerTitle px-3">{props.Title}</p>
        </div>
      </Col>

      <Modal
        show={show}
        onHide={handleModal}
        centered
        dialogClassName="speakerModal"
      >
        <Modal.Header closeButton></Modal.Header>
        <Modal.Body>
          <Row>
            <Col lg={6} className="modalImgCol">
              <img
                src={props.SpeakerImg}
                alt={props.Name}
                className="speakerImg mb-2"
              />
            </Col>
            <Col lg={6} className="modalName">
              <h5 className="speakerName mb-0">{props.Name}</h5>
              {props.BoldTitle ? (
                <div>
                  <strong>{props.BoldTitle}</strong>
                </div>
              ) : null}
              <p className="speakerTitle">{props.Title}</p>
            </Col>
          </Row>
          <p className="my-2 mt-3 speakerDescription">{props.Bio}</p>
        </Modal.Body>
      </Modal>
    </>
  );
}
