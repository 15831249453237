import React from "react";
import Form from "react-bootstrap/Form";
import Dropdown from "react-bootstrap/Dropdown";

type Props = {
  label: string;
  labelClickable?: string;
  clickable?: any;
  labelSpan?: string;
  defaultPlaceholder: string;
  labelState: string;
  stateSetter: React.Dispatch<React.SetStateAction<string>>;
  dropdownOptions: string[];
  removeSelectionOption?: string;
};

const target = (
  event: any,
  stateToSave: React.Dispatch<React.SetStateAction<string>>
) => {
  const targetElement = event.target as HTMLElement;
  stateToSave((prevState) => (prevState = targetElement.innerText));
};

const handleDefault = (stateToReset: React.Dispatch<React.SetStateAction<string>>) => {
  stateToReset("");
}

export default function RegisterDropdown(props: Props) {
  return (
    <>
      <Form.Label className="formTitle" htmlFor="agencyName">
        {props.label}
        {props.labelSpan ? <span> {props.labelSpan}</span> : null}
        {props.labelClickable ? (
          <a
            className="agendaGlanceBtn"
            href={props.clickable}
            target="_blank"
            rel="noreferrer"
          >
            {props.labelClickable}
          </a>
        ) : null}
      </Form.Label>
      <Dropdown className="registerDropdown">
        <Dropdown.Toggle size="lg" id="dropdown-basic">
          {props.labelState === "" ? (
            props.defaultPlaceholder
          ) : (
            <span className="darkText">{props.labelState}</span>
          )}
        </Dropdown.Toggle>
        <Dropdown.Menu>
          {props.removeSelectionOption ? 
            <Dropdown.Item onClick={() => handleDefault(props.stateSetter)}>
              {props.removeSelectionOption}
            </Dropdown.Item>
          : null}
          {props.dropdownOptions.map((option, index) => {
            return (
              <Dropdown.Item
                key={index}
                onClick={(e: React.FormEvent) => target(e, props.stateSetter)}
              >
                {option}
              </Dropdown.Item>
            );
          })}
        </Dropdown.Menu>
      </Dropdown>
    </>
  );
}
