import React from "react";
import ConfNavbar from "./ConfNavbar";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Button from "react-bootstrap/Button";
import { GetAllSpeakers } from "../services/apiService";
import SpeakerCard from "./SpeakerCard";
import "../styles/speakersStyles.css";
import { ConferenceSpeakers, Speaker } from "../services/testApiData";
import { TitleUpdate } from "../services/TitleUpdater";

const Speakers: React.FC<{ Speakers: Speaker[] }> = (props): JSX.Element => {
  TitleUpdate("CAPTAIN Summit - Speakers");
  const speakerData = ConferenceSpeakers;
  return (
    <div>
      <div className="confBg">
        <ConfNavbar />
      </div>
      <h1 className="title d-flex justify-content-center mt-5 mb-4">
        CAPTAIN Summit Speakers
      </h1>
      <Container>
        <Row>
          {props.Speakers.length === 0
            ? speakerData.map((speaker, index) => {
                return (
                  <SpeakerCard
                    key={index}
                    SpeakerImg={speaker.AvatarUrl}
                    Bio={speaker.Bio}
                    Name={speaker.Name}
                    Title={speaker.Title}
                    BoldTitle={speaker.BoldTitle}
                  />
                );
              })
            : props.Speakers.map((speaker, index) => {
                return (
                  <SpeakerCard
                    key={index}
                    SpeakerImg={speaker.AvatarUrl}
                    Bio={speaker.Bio}
                    Name={speaker.Name}
                    Title={speaker.Title}
                    BoldTitle={speaker.BoldTitle}
                  />
                );
              })}
        </Row>
      </Container>
    </div>
  );
};

export default Speakers;
