import User from './user';

class Transaction {
	public TransactionId: number = 0;
	public Name: string  = "";
	public Email: string  = "";
	public Phone: string  = "";
	public PaymentType: string  = "";
	public FileName: string  = "";
	public DateCreated?: Date;
	public Address: string  = "";
	public City: string  = "";
	public State: string  = "";
	public Zip: string  = "";
	public PONumber: string  = "";
	public PaymentTotal: number  = 0;
	public Organization: string  = "";
	public Hash: string  = "";
	public StatusId: number = 3;
	public InvoiceNumber: string  = "";
	public CreditTransactionId: number = 0;
	public EmailSend: boolean = false;
	public Users: User[] = [];
	public StatusName: string = "";
	public PayPortalLink: string = "";

	constructor(){
		this.TransactionId = 0;
	}
}

export default Transaction