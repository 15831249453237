import React from "react";
import Form from 'react-bootstrap/Form';

type Props = {
  label: string;
  labelSpan?: string,
  htmlLabel: string;
  placeholder: string;
  inputState: string;
  stateSetter: React.Dispatch<React.SetStateAction<string>>;
  characterLimit?: number;
  requiredBoolean?: boolean;
  inputType?: string;
};

export default function RegisterInput(props: Props) {
  return (
    <>
      <Form.Label className="formTitle" htmlFor={props.htmlLabel}>
        {props.label}<span> {props.labelSpan}</span>
      </Form.Label>
      <Form.Control
        className="formInput"
        type={props.inputType ? props.inputType : "text"}
        id={props.htmlLabel}
        aria-describedby={`${props.htmlLabel}HelpBlock`}
        placeholder={props.inputState === '' ? props.placeholder : props.inputState}
        onChange={(e: any) => props.stateSetter(e.target.value)}
        maxLength={props.characterLimit ? props.characterLimit : 50}
        required={props.requiredBoolean}
      />
      <Form.Text id={`${props.htmlLabel}HelpBlock`} muted></Form.Text>
    </>
  );
}
