import React from "react";
import ConfNavbar from "./ConfNavbar";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import Button from "react-bootstrap/Button";
import "../styles/surveyStyles.css";
import { TitleUpdate } from "../services/TitleUpdater";
type Props = {};

const Surveys: React.FC = (props: Props): JSX.Element => {
  TitleUpdate("CAPTAIN Summit - Surveys");
  return (
    <div>
      <div className="confBg">
        <ConfNavbar />
      </div>
      <h1 className="title d-flex justify-content-center mb-4 mt-5">SURVEYS</h1>
      <Container>
        <p className="subtext">If you are requesting CEUs for attending the Boot Camp or Workshops you must complete the Pre Test, Post Test, and  Satisfaction Surveys.</p>
        <Row className="surveyRow">
          {/* Boot Camp Surveys */}
          <p className="registrationTitle">Boot Camp</p>
          <Col lg="12" className="survey mx-2 my-2">
            <h5 className="surveyTitle">Pre-Knowledge Assessment</h5>
            <p className="surveyDescription">
              The Pre-Knowledge Assessment must be completed prior to attending the
              Boot Camp.
            </p>
            <Row className="surveyBtn mt-4">
              <Col lg="6" md="12" sm="12" className=" p-0">
                <Button className="gradientBtn m-0" href="https://forms.gle/hKdZXMvQ7hVfShPS7" target="_blank">Complete Survey</Button>
              </Col>
              <Col
                lg="6"
                md="12"
                sm="12"
                className="d-flex justify-content-center mt-2 p-0"
              >
                <p className="surveyTime">Complete before Nov 2, 2022</p>
              </Col>
            </Row>
          </Col>
          <Col lg="12" className="survey mx-2 my-2">
            <h5 className="surveyTitle">Post-Knowledge Assessment</h5>
            <p className="surveyDescription">
              The Post-Conference Survey must be completed after attending the
              Boot Camp.
            </p>
            <Row className="surveyBtn mt-4">
              <Col lg="6" md="12" sm="12" className="p-0">
                <Button className="gradientBtn m-0" href="https://forms.gle/8pEFAc3PkuVjLhgk8" target="_blank">Complete Survey</Button>
              </Col>
              <Col
                lg="6"
                md="12"
                sm="12"
                className="d-flex justify-content-center mt-2 p-0"
              >
                <p className="surveyTime">Complete by Nov 10, 2022</p>
              </Col>
            </Row>
          </Col>
          <Col lg="12" className="survey mx-2 my-2">
            <h5 className="surveyTitle">Satisfaction Survey</h5>
            <p className="surveyDescription">
              The Post-Conference Survey must be completed after attending the
              Boot Camp.
            </p>
            <Row className="surveyBtn mt-4">
              <Col lg="6" md="12" sm="12" className="p-0">
                <Button className="gradientBtn m-0" href="https://forms.gle/o61C8phBkxt7jycx9" target="_blank">Complete Survey</Button>
              </Col>
              <Col
                lg="6"
                md="12"
                sm="12"
                className="d-flex justify-content-center mt-2 p-0"
              >
                <p className="surveyTime">Complete by Nov 10, 2022</p>
              </Col>
            </Row>
          </Col>
          {/* Workshop 1 Surveys */}
          <p className="registrationTitle mt-4">Workshop 1: Classroom Pivotal Response Teaching (CPRT)</p>
          <Col lg="12" className="survey mx-2 my-2">
            <h5 className="surveyTitle">Pre-Knowledge Assessment</h5>
            <p className="surveyDescription">
              The Pre-Conference Survey must be completed prior to attending Workshop 1.
            </p>
            <Row className="surveyBtn mt-4">
              <Col lg="6" md="12" sm="12" className=" p-0">
                <Button className="gradientBtn m-0" href="https://forms.gle/C5JntRrsbSGfTHoRA" target="_blank">Complete Survey</Button>
              </Col>
              <Col
                lg="6"
                md="12"
                sm="12"
                className="d-flex justify-content-center mt-2 p-0"
              >
                <p className="surveyTime">Complete before Nov 2, 2022</p>
              </Col>
            </Row>
          </Col>
          <Col lg="12" className="survey mx-2 my-2">
            <h5 className="surveyTitle">Post-Knowledge Assessment</h5>
            <p className="surveyDescription">
              The Post-Conference Survey must be completed after attending Workshop 1.
            </p>
            <Row className="surveyBtn mt-4">
              <Col lg="6" md="12" sm="12" className="p-0">
                <Button className="gradientBtn m-0" href="https://forms.gle/wbbYBqFP24fMoWxT6" target="_blank">Complete Survey</Button>
              </Col>
              <Col
                lg="6"
                md="12"
                sm="12"
                className="d-flex justify-content-center mt-2 p-0"
              >
                <p className="surveyTime">Complete by Nov 10, 2022</p>
              </Col>
            </Row>
          </Col>
          <Col lg="12" className="survey mx-2 my-2">
            <h5 className="surveyTitle">Satisfaction Survey</h5>
            <p className="surveyDescription">
              The Post-Conference Survey must be completed after attending Workshop 1.
            </p>
            <Row className="surveyBtn mt-4">
              <Col lg="6" md="12" sm="12" className="p-0">
                <Button className="gradientBtn m-0" href="https://forms.gle/NTsQGiV2iKcnss5D6" target="_blank">Complete Survey</Button>
              </Col>
              <Col
                lg="6"
                md="12"
                sm="12"
                className="d-flex justify-content-center mt-2 p-0"
              >
                <p className="surveyTime">Complete by Nov 10, 2022</p>
              </Col>
            </Row>
          </Col>
          {/* Workshop 2 Surveys */}
          <p className="registrationTitle mt-4">Workshop 2: Supporting English Learners with Autism</p>
          <Col lg="12" className="survey mx-2 my-2">
            <h5 className="surveyTitle">Pre-Knowledge Assessment</h5>
            <p className="surveyDescription">
              The Pre-Conference Survey must be completed prior to attending Workshop 2.
            </p>
            <Row className="surveyBtn mt-4">
              <Col lg="6" md="12" sm="12" className=" p-0">
                <Button className="gradientBtn m-0" href="https://forms.gle/r8NQyDDLN92gBTEv6" target="_blank">Complete Survey</Button>
              </Col>
              <Col
                lg="6"
                md="12"
                sm="12"
                className="d-flex justify-content-center mt-2 p-0"
              >
                <p className="surveyTime">Complete before Nov 2, 2022</p>
              </Col>
            </Row>
          </Col>
          <Col lg="12" className="survey mx-2 my-2">
            <h5 className="surveyTitle">Post-Knowledge Assessment</h5>
            <p className="surveyDescription">
              The Post-Conference Survey must be completed after attending Workshop 2.
            </p>
            <Row className="surveyBtn mt-4">
              <Col lg="6" md="12" sm="12" className="p-0">
                <Button className="gradientBtn m-0" href="https://forms.gle/H9VEZ4vTYUGxscUr7" target="_blank">Complete Survey</Button>
              </Col>
              <Col
                lg="6"
                md="12"
                sm="12"
                className="d-flex justify-content-center mt-2 p-0"
              >
                <p className="surveyTime">Complete by Nov 10, 2022</p>
              </Col>
            </Row>
          </Col>
          <Col lg="12" className="survey mx-2 my-2">
            <h5 className="surveyTitle">Satisfaction Survey</h5>
            <p className="surveyDescription">
              This Satisfaction Survey must be completed after attending Workshop 2.
            </p>
            <Row className="surveyBtn mt-4">
              <Col lg="6" md="12" sm="12" className="p-0">
                <Button className="gradientBtn m-0" href="https://forms.gle/nmMvycsCPZT6mkyp7" target="_blank">Complete Survey</Button>
              </Col>
              <Col
                lg="6"
                md="12"
                sm="12"
                className="d-flex justify-content-center mt-2 p-0"
              >
                <p className="surveyTime">Complete by Nov 10, 2022</p>
              </Col>
            </Row>
          </Col>
          {/* Summit Surveys */}
          <p className="registrationTitle mt-4">Summit</p>
          <Col lg="12" className="survey mx-2 my-2">
            <h5 className="surveyTitle">Satisfaction Survey</h5>
            <p className="surveyDescription">This Satisfaction Survey must be completed after attending Summit.</p>
            <Row className="surveyBtn mt-4">
              <Col lg="6" md="12" sm="12" className=" p-0">
                <Button className="gradientBtn m-0" href="https://forms.gle/99mMJURgSmEr3i6M7" target="_blank">Complete Survey</Button>
              </Col>
              <Col
                lg="6"
                md="12"
                sm="12"
                className="d-flex justify-content-center mt-2 p-0"
              >
                <p className="surveyTime">Complete by Nov 10, 2022</p>
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
    </div>
  );
};
export default Surveys;
