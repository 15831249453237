class User {
	public UserId: number =0;
	public Name: string = "";
	public Title: string = "";
	public Phone: string = "";
	public Email: string = "";
	public Bio: string = "";
	public Avatar: string = "";
	public Org: string = "";
	public TypeName: string = "";
	public StatusName: string = "";
	public StatusId: number =0;
	public PaymentType: string = "";
	public ContactName: string = "";
	public ContactEmail: string = "";
	public ContactPhone: string = "";
	public Hash: string = "";
	public TransactionId: number =0;
	public CreditTransactionId: string = "";
	public InvoiceNumber: number =0;
	public DateCreated?: Date;
	public PaidDate?: Date;
	public TransactionCount: number =0;
	public SessionCount: number =0;
	public AvatarName: string = "";
	public TypeId: number =0;
	public PaidAmount: number =0;
	public PaymentTotal: number =0;
	public IsPresenting: boolean = false;
	public ConfId: number =0;
	public DevicePickUpDate?: Date;
	public Password: string = "";
	public CheckinDate?: Date;
	public ExhibitorId: number =0;
	public IsChecked: boolean = false;
	public ParticipantId: number =0;
	public CheckedInBy: string = "";
	public HasWon: boolean = false;
	public ExhibitorCsvurl: string = "";
	public ExhibitorCsvurlshort: string = "";
	public UserType: string = "";
	public SocialHour: string = "";
	public Hotel: string = "";
	public IsSeis: boolean = false;
	public IsEdjoin: boolean = false;
	public IsSST: boolean = false;
	public PastConfAttended: string = "";
	public UserUniqueID: string = "";
	public DeviceUniqueId: string = "";
	public AvatarUrl: string = "";
	public Image: string = "";
	public DisplayDateCreated: string = "";
	public IsVirtual: boolean = false;
	public SystemTypeId: number =0;
	public isAttendingBootCamp: boolean = false;
	public isAttendingWorkshop1: boolean = false;
	public isAttendingWorkshop2: boolean = false;
	public isAttendingMentorOrientation: boolean = false;
	public isAttendingCaptainSummit: boolean = true;
	public notApplicableCredit: boolean = false;
	public bacbCredit: boolean = false;
	public cpaCredit: boolean = false;
	public continuingPDHoursCredit: boolean = false;
	public lunch: string = "";
	public ceuEvents: string = "";
	public primaryRole: string = "";
	public bcbaCertificationNumber: string = "";
	public licenseType: string = "";
	public licenseNumber: string = "";
	public captainRegion: string = "";
	public isEarningCreditsAtBootCamp: boolean = false;
	public isEarningCreditsAtWorkshop1: boolean = false;
	public isEarningCreditsAtWorkshop2: boolean = false;
	public isEarningCreditsAtCaptainSummit: boolean = false;
	public isNotEarningCreditsAtEvent: boolean = false;
}

export default User