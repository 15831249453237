import React, { useEffect, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import ConfNavbar from "./ConfNavbar";
import { useParams, useLocation, useNavigate } from "react-router-dom";
import { TitleUpdate } from "../services/TitleUpdater";
import {
  CheckGuid,
  ForgotPassword,
  ResetPassword,
} from "../services/apiService";
import "../styles/PasswordStyles.css";

type Props = {};

export default function PasswordReset({}: Props) {
  TitleUpdate("CAPTAIN Summit - Password Reset");

  const navigate = useNavigate();
  const queryParams = new URLSearchParams(useLocation().search);
  const urlparams = useParams();
  let guidBool: boolean;
  let guidResponse: any;
  let criteria: boolean = false;
  const [userId, setId] = useState<number>(0);
  const [password, setPass] = useState("");
  const [passConfirm, setConfirm] = useState("");
  const [guid, setGuid] = useState();
  const [currentUser, setUser] = useState();
  const [isCharsValid, setCharValid] = useState(false);
  const [hasCapital, setCapital] = useState(false);
  const [hasLowercase, setLowercase] = useState(false);
  const [hasNumber, setNumber] = useState(false);
  const [hasSymbol, setSymbol] = useState(false);
  const [passwordMatch, setMatch] = useState(false);
  const passReq = [
    isCharsValid,
    hasCapital,
    hasLowercase,
    hasNumber,
    hasSymbol,
  ];

  useEffect(() => {
    handleGuid();
  }, []);

  const handleGuid = async () => {
    guidResponse = await CheckGuid(urlparams.guid);
    setId(guidResponse.UserId);
    if (guidResponse.Message !== undefined) {
      alert(`${guidResponse.Message}`);
      window.location.href = "/";
    }
  };

  const handlePassword = (e: any) => {
    let inputText = e.target.value;
    const capitalReg = new RegExp(/[A-Z]/);
    const lowerReg = new RegExp(/[a-z]/);
    const numberReg = new RegExp(/[0-9]/);
    const symbolReg = new RegExp(/[^\w\s]|[_]/);
    setPass(inputText);
    setCharValid(inputText.length > 7 ? true : false);
    setCapital(inputText.match(capitalReg) !== null ? true : false);
    setLowercase(inputText.match(lowerReg) !== null ? true : false);
    setNumber(inputText.match(numberReg) !== null ? true : false);
    setSymbol(inputText.match(symbolReg) !== null ? true : false);
    handleMatch(passConfirm, inputText);
  };

  const handleConfirm = (e: any) => {
    let inputText = e.target.value;
    setConfirm(inputText);
    handleMatch(inputText, password);
  };

  const handleMatch = (passwordConfirm: string, password: string) => {
    if (passwordConfirm === password) {
      setMatch(true);
    } else {
      setMatch(false);
    }
  };

  const submitPassword = async () => {
    if (isCharsValid && hasCapital && hasLowercase && hasNumber && hasSymbol) {
      criteria = true;
    } else {
      criteria = false;
    }
    if (criteria && passwordMatch) {
      // Submit Button here
      await ResetPassword(userId, password).then((response) => {
        if (response.status === 204) {
          alert("Password Changed Successfully.");
          window.location.href = "/";
        }else {
          alert(response.statusText);
        }
      });
    }
  };

  return (
    <>
      <div className="confBg">
        <ConfNavbar />
      </div>
      <h1 className="title d-flex justify-content-center mb-4 mt-5">
        Reset Password
      </h1>
      <Container className="resetPage">
        <Row>
          <Col xs={12}>
            <div className="m-4 d-flex justify-content-center flex-column">
              <strong className="d-flex justify-content-start">PASSWORD</strong>
              <input
                type="password"
                placeholder="Reset Password"
                onChange={(e) => handlePassword(e)}
              />
            </div>
          </Col>
          <Col xs={12}>
            <div className="d-flex justify-content-center flex-column">
              <p className="d-flex justify-content-start mx-4">
                Password Requirements:{" "}
                {!passReq.includes(false) ? (
                  <span>&#10004;</span>
                ) : (
                  <span>&#10060;</span>
                )}
              </p>
              {!passReq.includes(false) ? null : (
                <ul className="d-flexflex-column">
                  <li className="d-flex justify-content-start">
                    &#8226; 8 Characters minimum{" "}
                    {isCharsValid ? (
                      <span>&#10004;</span>
                    ) : (
                      <span>&#10060;</span>
                    )}
                  </li>
                  <li className="d-flex justify-content-start">
                    &#8226; At least 1 capital letter{" "}
                    {hasCapital ? <span>&#10004;</span> : <span>&#10060;</span>}
                  </li>
                  <li className="d-flex justify-content-start">
                    &#8226; At least 1 lowercase letter{" "}
                    {hasLowercase ? (
                      <span>&#10004;</span>
                    ) : (
                      <span>&#10060;</span>
                    )}
                  </li>
                  <li className="d-flex justify-content-start">
                    &#8226; At least 1 number{" "}
                    {hasNumber ? <span>&#10004;</span> : <span>&#10060;</span>}
                  </li>
                  <li className="d-flex justify-content-start">
                    &#8226; At least 1 symbol (!@#%^&*?_~-){" "}
                    {hasSymbol ? <span>&#10004;</span> : <span>&#10060;</span>}
                  </li>
                </ul>
              )}
            </div>
          </Col>
          <Col xs={12}>
            <div className="m-4 d-flex justify-content-center flex-column">
              <strong className="d-flex justify-content-start">
                CONFIRM PASSWORD
              </strong>
              <input
                type="password"
                placeholder="Confirm Password"
                onChange={(e) => handleConfirm(e)}
              />
              {passwordMatch ? null : (
                <p className="d-flex justify-content-start">
                  Passwords do not match <span>&#10060;</span>
                </p>
              )}
            </div>
          </Col>
          <Col xs={12}>
            <div>
              <button className="gradientBtn" onClick={submitPassword}>
                SUBMIT
              </button>
            </div>
          </Col>
        </Row>
      </Container>
    </>
  );
}
