import React, { useState, useEffect } from "react";
import Form from "react-bootstrap/Form";
import Dropdown from "react-bootstrap/Dropdown";

type Props = {
  properties: {
    label: string;
    labelSpan?: string;
    defaultStateValue: string[];
    checkType: string;
    buttonLabel: string;
    attendee?: string;
    selectState: string[];
    stateSetter: React.Dispatch<React.SetStateAction<string[]>>;
    priceValue?: number;
    setPrice?: React.Dispatch<React.SetStateAction<number>>;
    checkedStates: boolean[];
    checkedSetters: React.Dispatch<React.SetStateAction<boolean>>[];
    allAttendeeOptions: string[];
    dropdownOptions?: any;
  };
};

export default function CeuDropdown(props: Props) {
  const [optionValues, setValues] = useState<string[]>([]);
  let buttonLabel = props.properties.buttonLabel;

  useEffect(() => {
    // Default Value
    props.properties.stateSetter(props.properties.defaultStateValue);
    // Reset Checked States
    props.properties.checkedSetters.forEach((setter) => setter(false));
    setValues([]);
    // Need If for Resetting Price
    if (props.properties.setPrice != undefined) {
      props.properties.setPrice(150);
    }
  }, [props.properties.attendee]);

  const dropdownOptions = [
    {
      value: [1, 2, 3],
      label: "Not Applicable",
      isAllowed: props.properties.allAttendeeOptions,
      singleSelectOption: true
    },
    {
      value: [2],
      label: "Boot Camp",
      isAllowed: props.properties.allAttendeeOptions[0],
      singleSelectOption: false
    },
    {
      value: [2],
      label: "Workshop 1: Classroom Pivotal Response Teaching (CPRT)",
      isAllowed: props.properties.allAttendeeOptions[1],
      singleSelectOption: false
    },
    {
      value: [2],
      label: "Workshop 2: Supporting English Learners with Autism",
      isAllowed: props.properties.allAttendeeOptions[1],
      singleSelectOption: false
    },
    {
      value: [3],
      label: "Captain Summit 2022",
      isAllowed: props.properties.allAttendeeOptions,
      singleSelectOption: false
    },
  ];

  const handleCheckbox = (
    element: any,
    stateToSave: React.Dispatch<React.SetStateAction<string[]>>,
    state: string[],
    price: number,
    index: number
  ) => {
    const textToSave = element.target.nextSibling.innerText;
    const selectedValue = element.target.value;
    if (state.includes(textToSave)) {
      stateToSave((prevState) => {
        return prevState.filter((option) => option !== textToSave);
      });
      for (let i = 0; i < selectedValue.length; i++) {
        setValues((prevState) =>
          prevState.filter((value) => value !== selectedValue[i])
        );
      }
    } else if (!state.includes(textToSave)) {
      stateToSave((prevState) => [...prevState, textToSave]);
      setValues((prevState) => [...prevState, ...selectedValue]);
    }
    if (props.properties.priceValue && props.properties.setPrice) {
      if (!optionValues.includes(selectedValue)) {
        props.properties.setPrice((prevPrice) => (prevPrice += price));
      } else if (optionValues.includes(selectedValue)) {
        props.properties.setPrice((prevPrice) => prevPrice - price);
      }
    }
    props.properties.checkedSetters[index](
      !props.properties.checkedStates[index]
    );
  };

  const formCheckbox = (
    option: {
      value: number;
      label: string;
      price: number;
      singleSelectOption: boolean;
    },
    index: number,
    isDisablable?: boolean
  ) => {
    let form = (
      <Form.Check
        key={index}
        value={option.value}
        type="checkbox"
        id={`${props.properties.checkType}${index}`}
        label={option.label}
        checked={props.properties.checkedStates[index]}
        disabled={
          isDisablable
            ? !props.properties.selectState.includes(option.label) &&
              (optionValues.includes(option.value.toString()) ||
                (props.properties.selectState.length > 0 &&
                  option.singleSelectOption))
            : false
        }
        onChange={(e: any) => {
          handleCheckbox(
            e,
            props.properties.stateSetter,
            props.properties.selectState,
            option.price,
            index
          );
        }}
      />
    );
    if (option.price === 150) {
      form = (
        <Form.Check
          key={index}
          value={option.value}
          type="checkbox"
          id={`${props.properties.checkType}${index}`}
          label={option.label}
          disabled={true}
          checked={true}
          onChange={(e: any) => {
            handleCheckbox(
              e,
              props.properties.stateSetter,
              props.properties.selectState,
              option.price,
              index
            );
          }}
        />
      );
    }
    return form;
  };

  return (
    <>
      <Form.Label className="formTitle" htmlFor="eventRegistration">
        {props.properties.label}
        {props.properties.labelSpan ? (
          <span> {props.properties.labelSpan}</span>
        ) : null}
      </Form.Label>
      <Dropdown className="registerDropdown">
        <Dropdown.Toggle className="py-2">
          {props.properties.selectState.length == 0 ? (
            buttonLabel
          ) : props.properties.selectState.length == 1 && props.properties.selectState.includes("Not Applicable") ? (
            <span className="darkText">{props.properties.selectState}</span>
          ) : (
            <span className="darkText">
              {props.properties.selectState.length} selection(s)
            </span>
          )}
        </Dropdown.Toggle>
        <Dropdown.Menu className="p-3 pt-2 pb-2">
          {dropdownOptions.map((option: any, index: number) => {
            if (
              option.isAllowed.includes(props.properties.attendee) ||
              props.properties.attendee == ""
            ) {
              return formCheckbox(option, index, true);
            }
          })}
        </Dropdown.Menu>
      </Dropdown>
    </>
  );
}