import LauraH from "../assets/speakers/Photo.png";
import Jessica from "../assets/speakers/Photo-2.png";
import Diane from "../assets/speakers/Photo-3.png";
import Dennis from "../assets/speakers/Photo-4.png";
import Ann from "../assets/speakers/Photo-6.png";
import Andrew from "../assets/speakers/Photo-7.png";
import Lauren from "../assets/speakers/Photo-8.png";
import JenniferR from "../assets/speakers/Photo-9.png";
import JenniferW from "../assets/speakers/Photo-10.png";
import JenniferS from "../assets/speakers/Photo-11.png";
import Joan from "../assets/speakers/Photo-12.png";
import Kristi from "../assets/speakers/Photo-13.png";
import LauraB from "../assets/speakers/Photo-14.png";
import Marissa from "../assets/speakers/Photo-15.png";
import Mark from "../assets/speakers/Photo-16.png";
import Mary from "../assets/speakers/Photo-17.png";
import Monika from "../assets/speakers/Photo-18.png";
import Nicola from "../assets/speakers/Photo-19.png";
import Patti from "../assets/speakers/Photo-20.png";
import Patricia from "../assets/speakers/Photo-21.png";
import Robert from "../assets/speakers/Photo-22.png";
import Robin from "../assets/speakers/Photo-23.png";
import Yvette from "../assets/speakers/Photo-24.png";
import Heather from "../assets/speakers/Photo-25.jpg";
import Karin from "../assets/speakers/Photo-26.png";
import Melina from "../assets/speakers/Photo-27.png";
import Regina from "../assets/speakers/Photo-28.jpg";
import Rosy from "../assets/speakers/Photo-29.png";
import Sharon from "../assets/speakers/Photo-30.jpg";
import James from "../assets/speakers/Photo-31.jpg";
import JenniferF from "../assets/speakers/Photo-32.jpg";
import Amber from "../assets/speakers/Photo-33.png";
import Vanessa from "../assets/speakers/Photo-34.jpg";
import Janice from "../assets/speakers/Photo-35.jpg";
import LauraM from "../assets/speakers/Photo-36.png";
import Tana from "../assets/speakers/Photo-37.jpg";
import Lupita from "../assets/speakers/Photo-38.jpg";
import RobertR from "../assets/speakers/Photo-39.png";
import Scott from "../assets/speakers/Photo-40.jpg";
import VanessaS from "../assets/speakers/Photo-41.jpg";
import Nicole from "../assets/speakers/Photo-42.png";
import ChristinaZ from "../assets/speakers/Photo-43.png";
import Awit from "../assets/speakers/Photo-44.jpg";
export interface Speaker {
    AvatarUrl: string;
    Bio?: string;
    Name: string;
    Title: string;
    BoldTitle?: string;
}

export interface Session {
    time: string;
    title: string;
    presenter: string;
    presenterImage: string;
    presenter2?: string;
    presenter2Image?: string;
    presenter3?: string;
    presenter3Image?: string;
    presenter4?: string;
    presenter4Image?: string;
    presenter5?: string;
    presenter5Image?: string;
}

const ConferenceSpeakers: Speaker[] = [
    {
        AvatarUrl: Robin,
        Bio: "",
        Name: "Robin Ancona",
        Title: "CAPTAIN San Diego",
    },
    {
        AvatarUrl: Patti,
        Bio: "",
        Name: "Patti Bangs",
        Title: "Regional Implementation Lead, CAPTAIN 007",
    },
    {
        AvatarUrl: Yvette,
        Bio: "",
        Name: "Yvette Baptiste",
        Title: "Coordinator Family Support, CAPTAIN Leader",
    },
    {
        AvatarUrl: Kristi,
        Bio: "",
        Name: "Kristi Baysinger",
        Title: "Regional Implementation Lead, CAPTAIN H.O.O.K.",
    },
    {
        AvatarUrl: LauraB,
        Bio: "",
        Name: "Laura Blackburn",
        Title: "Regional Implementation Lead, Capital CAPTAIN",
    },
    {
        AvatarUrl: Rosy,
        Bio: "",
        Name: "Rosy Bucio",
        Title: "CAPTAIN 805",
    },
    {
        AvatarUrl: Janice,
        Bio: "",
        Name: "Janice Chan",
        Title: "M.A., BCBA CPRT Lead Trainer UC Davis MIND Institute"
    },
    {
        AvatarUrl: Awit,
        Bio: "",
        Name: "Awit Dalusong",
        Title: "CAPTAIN Leader, CAPTAIN X"
    },
    {
        AvatarUrl: Ann,
        Bio: "",
        Name: "Ann England",
        Title: "Co-Coordinator, CAPTAIN",
    },
    {
        AvatarUrl: JenniferF,
        Bio: "",
        Name: "Jennifer Fisher",
        Title: "CAPTAIN Leader, CAPTAINs of the New Wave"
    },
    {
        AvatarUrl: Amber,
        Bio: "",
        Name: "Amber Fitzgerald",
        Title: "M.A., BCBA CPRT Trainer UC Davis MIND Institute"
    },
    {
        AvatarUrl: Sharon,
        Bio: "",
        Name: "Sharon Floyd",
        Title: "Distinguished Life Fellow of CAPTAIN, C.L.I.C.",
        BoldTitle: "CAPTAIN 2022 SUMMIT KEYNOTE SPEAKER,"
    },
    {
        AvatarUrl: Monika,
        Bio: "",
        Name: "Monika Gylys",
        Title: "Regional Implementation Lead, CAPTAIN SUPER 14",
    },
    {
        AvatarUrl: LauraH,
        Bio: "",
        Name: "Laura Hall",
        Title: "Ph.D. Consultant to CAPTAIN Leadership, CAPTAIN Leader",
    },
    {
        AvatarUrl: Robert,
        Bio: "",
        Name: "Robert Hefner",
        Title: "Regional Implementation Lead, CAPTAIN Kern",
    },
    {
        AvatarUrl: Tana,
        Bio: "",
        Name: "Tana Holt",
        Title: "CAPTAIN Research Assistant",
    },
    {
        AvatarUrl: Karin,
        Bio: "",
        Name: "Karin Jinbo",
        Title: "CAPTAIN Leader, CAPTAIN 007",
    },
    {
        AvatarUrl: James,
        Bio: "",
        Name: "James Koontz",
        Title: "Regional Implementation Lead, CAPTAIN LA"
    },
    {
        AvatarUrl: Lauren,
        Bio: "",
        Name: "Lauren Libero",
        Title: "Autism Program Specialist, DDS, CAPTAIN Leader",
    },
    {
        AvatarUrl: Vanessa,
        Bio: "",
        Name: "Vanessa Lopez",
        Title: "M.A. Coordinator, Imperial County SELPA, SELPA Content Lead - English Learners with Disabilities"
    },
    {
        AvatarUrl: LauraM,
        Bio: "",
        Name: "Laura Mahoney",
        Title: "Program Manager III Codestack San Joaquin County Office of Education"
    },
    {
        AvatarUrl: Heather,
        Bio: "",
        Name: "Heather Maurin",
        Title: "CAPTAIN Leader, CAPTAIN 007",
    },
    {
        AvatarUrl: Melina,
        Bio: "",
        Name: "Melina Melgarejo",
        Title: "Ph.D., Research Scientist / Lecturer, Special Education San Diego University Coordinator Higher Education, CAPTAIN Leader",
    },
    {
        AvatarUrl: Lupita,
        Bio: "",
        Name: "Lupita Olguin-Rubio",
        Title: "M.A. Coordinator, Imperial County SELPA, SELPA Content Lead-English Leaners with Disabilities",
    },
    {
        AvatarUrl: Mark,
        Bio: "",
        Name: "Mark Pfaff",
        Title: "Regional Implementation Lead, North State CAPTAIN",
    },
    {
        AvatarUrl: Joan,
        Bio: "",
        Name: "Joan Ralph",
        Title: "Regional Implementation Lead, CAPTAINs of the East Bay",
    },
    {
        AvatarUrl: Regina,
        Bio: "",
        Name: "Regina Reed",
        Title: "CAPTAIN Leader, CAPTAIN 805",
    },
    {
        AvatarUrl: Mary,
        Bio: "",
        Name: "Mary Rettinhouse",
        Title: "Coordinator for Allied Health and Regional Centers, CAPTAIN Leader",
    },
    {
        AvatarUrl: RobertR,
        Bio: "",
        Name: "Robert Rochin",
        Title: "CAPTAIN Leader, Family Support"
    },
    {
        AvatarUrl: JenniferR,
        Bio: "",
        Name: "Jennifer Rountree",
        Title: "Regional Implementation Lead, CAPTAIN X",
    },
    {
        AvatarUrl: Marissa,
        Bio: "",
        Name: "Marissa Saldate",
        Title: "Regional Implementation Lead, Central Valley CAPTAIN",
    },
    {
        AvatarUrl: Nicola,
        Bio: "",
        Name: "Nicola Saltzman",
        Title: "Regional Implementation Lead, Orange County CAPTAINs",
    },
    {
        AvatarUrl: Patricia,
        Bio: "",
        Name: "Patricia Schetter",
        Title: "Co-Coordinator, CAPTAIN",
    },
    {
        AvatarUrl: Scott,
        Bio: "",
        Name: "Scott Schwartz",
        Title: "CAPTAIN Leader, CAPTAIN H.O.O.K.",
    },
    {
        AvatarUrl: VanessaS,
        Bio: "",
        Name: "Vanessa Smith",
        Title: "CAPTAIN Leader, CAPTAINs of the New Wave",
    },
    {
        AvatarUrl: Diane,
        Bio: "",
        Name: "Diane Storman",
        Title: "CAPTAIN Leader, Family Support",
    },
    {
        AvatarUrl: JenniferS,
        Bio: "",
        Name: "Jennifer Streeter",
        Title: "Regional Implementation Lead, CAPTAIN 101",
    },
    {
        AvatarUrl: Jessica,
        Bio: "",
        Name: "Jessica Suhrheinrich",
        Title: "Coordinator of Research and QI, CAPTAIN Leader",
    },
    {
        AvatarUrl: Dennis,
        Bio: "",
        Name: "Dennis Sweningson",
        Title: "Regional Implementation Lead, CAPTAINs of the New Wave",
    },
    {
        AvatarUrl: Nicole,
        Bio: "",
        Name: "Nicole Ward",
        Title: "CAPTAIN Leader, CAPTAIN LA",
    },
    {
        AvatarUrl: Andrew,
        Bio: "",
        Name: "Andrew Weiher",
        Title: "Regional Implementation Lead, CAPTAIN Bridges",
    },
    {
        AvatarUrl: JenniferW,
        Bio: "",
        Name: "Jennifer Wolfe",
        Title: "Regional Implementation Lead, CAPTAIN C.L.I.C.",
    },
    {
        AvatarUrl: ChristinaZ,
        Bio: "",
        Name: "Christina Zavala",
        Title: "M.A. Coordinator - Imperial County SELPA, SELPA Content Lead-English Learners with Disabilities"
    }
];

const ConferenceAgenda: Session[] = [
    {
        time: "2:15 PM - 4:15PM (2 Hours)",
        title: "Boot Camp All New CAPTAIN Cadre (Required for New Cadre)",
        presenter: "Patricia Schetter",
        presenterImage: Patricia,
        presenter2: "Patti Bangs",
        presenter2Image: Patti,
        presenter3: "Robert Hefner",
        presenter3Image: Robert,
        presenter4: "Nicola Saltzman",
        presenter4Image: Nicola,
        presenter5: "Marissa Saldate",
        presenter5Image: Marissa
    },
    {
        time: "2:15 PM - 4:15 PM (2 Hours)",
        title: "Boot Camp New Member Mentoring Session - Family Support",
        presenter: "Diane Storman",
        presenterImage: Diane,
    },
    {
        time: "2:15 PM - 4:15 PM (2 Hours)",
        title: "Boot Camp New Member Mentoring Session - Higher Ed",
        presenter: "Laura Hall",
        presenterImage: LauraH
    }
];

export { ConferenceSpeakers, ConferenceAgenda };
