import React, { useState, useEffect } from "react";
import ConfNavbar from "./ConfNavbar";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { useParams, useLocation, useNavigate } from "react-router-dom";
import { UpdatePayment } from "../services/apiService";
import Table from "react-bootstrap/Table";
import { TitleUpdate } from "../services/TitleUpdater";

type Props = {};

const Confirmation: React.FC = (): JSX.Element => {
  TitleUpdate("CAPTAIN Summit - Confirmation");
  const [isLoading, setLoading] = useState(true);
  const [invoiceInfo, setInvoice] = useState<any>();

  // let routeParams = useParams();
  const navigate = useNavigate();
  const queryParams = new URLSearchParams(useLocation().search);
  useEffect(() => {
    setLoading(true);
    var transactionId: number = parseInt(queryParams.get("uID")!) || 0;
    var creditId: string = queryParams.get("tID") || "";
    // console.log(transactionId, creditId);
    if (transactionId > 0 && creditId.length > 0) {
      // setInvoice(UpdatePayment(transactionId,creditId));
      handleConfirmation(transactionId, creditId);
    } else {
      window.location.href = "/";
      setLoading(false);
    }
  }, []);

  const handleConfirmation = async (
    transactionId: number,
    creditId: string
  ) => {
    setInvoice(await UpdatePayment(transactionId, creditId));
    setLoading(false);
  };

  // const handleInvoiceClick = () => {
  //   console.log("Hello invoice: ", invoiceInfo.Users[0].isAttendingBootCamp);
  // };

  return (
    <>
      <div className="confBg">
        <ConfNavbar />
      </div>
      <h1 className="title d-flex justify-content-center mt-5 mb-4">
        CONFIRMATION
      </h1>
      <Container>
        {/* <button onClick={handleInvoiceClick}>Click this for invoice</button> */}
        {isLoading ? (
          <div className="spinner">
            <p>Loading...</p>
          </div>
        ) : invoiceInfo !== undefined ? (
          <>
            <Row>
              <Col></Col>
              <Col sm="12" lg="8" className="confirmColumn">
                <h3 className="registrationTitle d-flex justify-content-start">
                  CAPTAIN Summit Order
                </h3>
                <h5 className="d-flex justify-content-start">
                  <strong className="my-2">Order Details:</strong>
                </h5>
                <p className="d-flex justify-content-start">
                  <strong>Organization:</strong>
                  <span className="mx-2">{invoiceInfo.Organization}</span>
                </p>
                <p className="d-flex justify-content-start">
                  <strong>Name:</strong>
                  <span className="mx-2"> {invoiceInfo.Name}</span>
                </p>
                <p className="d-flex justify-content-start">
                  <strong>Email:</strong>
                  <span className="mx-2"> {invoiceInfo.Email}</span>
                </p>
                <p className="d-flex justify-content-start">
                  <strong>Phone:</strong>
                  <span className="mx-2"> {invoiceInfo.Phone}</span>
                </p>
                <br />
                <Row>
                  <Col>
                    <Table>
                      <thead>
                        <tr>
                          <th className="d-flex justify-content-start">
                            Registered Events
                          </th>
                          <th>Total</th>
                        </tr>
                      </thead>
                      <tbody>
                        {invoiceInfo.Users[0].isAttendingBootCamp ? (
                          <tr>
                            <td className="d-flex justify-content-start">
                              Boot Camp
                            </td>
                            <td><strong>$50</strong></td>
                          </tr>
                        ) : null}
                        {invoiceInfo.Users[0].isAttendingWorkshop1 ? (
                          <tr>
                            <td className="d-flex justify-content-start">
                              Workshop 1: Classroom Pivotal Response Teaching (CPRT)
                            </td>
                            <td><strong>$50</strong></td>
                          </tr>
                        ) : null}
                        {invoiceInfo.Users[0].isAttendingWorkshop2 ? (
                          <tr>
                            <td className="d-flex justify-content-start">
                              Workshop 2: Supporting English Learners with Autism
                            </td>
                            <td><strong>$50</strong></td>
                          </tr>
                        ) : null}
                        {invoiceInfo.Users[0].isAttendingCaptainSummit ? (
                          <tr>
                            <td className="d-flex justify-content-start">
                              CAPTAIN Summit 2022
                            </td>
                            <td><strong>$150</strong></td>
                          </tr>
                        ) : null}
                      </tbody>
                    </Table>
                  </Col>
                </Row>
                <h6 className="d-flex justify-content-start mt-5 my-3">
                  <strong>Payment details:</strong>
                </h6>
                <p className="d-flex justify-content-start m-0">
                  Transaction number: {invoiceInfo.TransactionId}
                </p>
                <p className="d-flex justify-content-start">
                  Product: CAPTAIN Summit Registration
                </p>
                <h6 className="d-flex justify-content-start my-5">
                  <strong>TOTAL AMOUNT: ${invoiceInfo.PaymentTotal}</strong>
                </h6>
              </Col>
              <Col></Col>
            </Row>
          </>
        ) : null}
      </Container>
    </>
  );
};

export default Confirmation;
