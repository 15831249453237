import React from "react";
import { Dropdown, Form } from "react-bootstrap";

type Props = {
  properties: {
    state: string[];
    stateSetter: React.Dispatch<React.SetStateAction<string[]>>;
    checkedStates: boolean[];
    checkedSetters: React.Dispatch<React.SetStateAction<boolean>>[];
  };
};

export default function stateDropdown(props: Props) {

  const handleCheckbox = (e: any, index: number) => {
    const textToSave = e.target.nextSibling.textContent;
    if (props.properties.state.includes(textToSave)) {
      props.properties.stateSetter((prevState) =>
        prevState.filter((value) => value !== textToSave)
      );
    } else if (!props.properties.state.includes(textToSave)) {
      props.properties.stateSetter((prevState) => [...prevState, textToSave]);
    }
    props.properties.checkedSetters[index](!props.properties.checkedStates[index]);
  };

  const dropdownOptions = [
    "Not Applicable",
    "BACB (BCBAs, BCABAs)",
    "CPA (LMFTs, LCSEs, LEPs, or Lic. Psychologist)",
    "SLP continuing Professional Development Hours"
  ]
  
  return (
    <>
      <Form.Label className="formTitle" htmlFor="registerDropdown">
        Which type of credits do you want to sign up for?
      </Form.Label>
      <Dropdown className="registerDropdown">
        <Dropdown.Toggle size="lg" id="dropdown-basic">
          {props.properties.state.length === 0 ? (
            "Select credits"
          ) : props.properties.state.includes("Not Applicable") ? (
            <span className="darkText">No credits selected</span>
          ) : (
            <span className="darkText">
              {props.properties.state.length} credit(s) selected
            </span>
          )}
        </Dropdown.Toggle>
        <Dropdown.Menu className="p-3 pt-2 pb-2">
            {dropdownOptions.map((option, index) => {
                const checkId = `creditCheck${index+1}`
                let disabledBool = props.properties.state.length != 0 && !props.properties.state.includes("Not Applicable") ? true : false
                if(index != 0){
                    disabledBool = props.properties.state.includes("Not Applicable") ? true : false
                }
                return(
                    <Form.Check 
                        key={index}
                        type="checkbox"
                        id={checkId}
                        label={option}
                        onChange={(e: any) => handleCheckbox(e,index)}
                        disabled={disabledBool}
                    />
                )
            })}
        </Dropdown.Menu>
      </Dropdown>
    </>
  );
}
