import React, { useState, useEffect } from "react";
import ConfNavbar from "./ConfNavbar";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import "../styles/registerStyles.css";
import RegisterDropdown from "./RegisterDropdown";
import RegisterInput from "./RegisterInput";
import EventDropdown from "./EventDropdown";
import Transaction from "../models/transaction";
import User from "../models/user";
import CreditDropdown from "./CreditDropdown";
import CeuDropdown from "./CeuDropdown";
import Modal from "react-bootstrap/Modal";
import BootcampAgendaPDF from "../agendas/DAY 1 BOOT CAMP AGENDA.pdf";
import WorkshopAgendaPDF from "../agendas/DAY 1 WORKSHOPS AGENDA.pdf";
import SummitAgendaPDF from "../agendas/DAY 2 CAPTAIN 2022 SUMMIT AGENDA.pdf";
import Form from "react-bootstrap/Form";
import RegionPDF from "../assets/CAPTAIN Regions.pdf";
import { saveRegistration } from "../services/apiService";
import { TitleUpdate } from "../services/TitleUpdater";

const Register: React.FC = (): JSX.Element => {
  TitleUpdate("CAPTAIN Summit - Registration");
  const [show, setShow] = useState(false);
  const handleModal = () => {
    setShow(!show);
  };

  const [attendeeName, setName] = useState("");
  const [agency, setAgency] = useState("");
  const [attendeeType, setType] = useState("");
  const [region, setRegion] = useState("");
  const [phoneNumber, setPhone] = useState("");
  const [address, setAddress] = useState("");
  const [city, setCity] = useState("");
  const [state, setState] = useState("");
  const [zip, setZip] = useState("");
  const [email, setEmail] = useState("");
  const [emailConfirm, setConfirm] = useState("");
  //Event Registration has 5 associated fields on api
  const [eventRegister, setRegistration] = useState<string[]>([
    "Day 2 CAPTAIN 2022 Summit Required for ALL - $150",
  ]);
  const [lunch, setLunch] = useState("");
  const [ceuEvent, setCeuevent] = useState<string[]>([]);
  const [role, setRole] = useState("");
  //Credit has 4 associated fields on api
  const [credit, setCredit] = useState<string[]>([]);
  const [certNumber, setCert] = useState("");
  const [license, setLicense] = useState("");
  const [licenseNumber, setLnumber] = useState("");
  const [eventPrice, setPrice] = useState(150);

  //Booleans for CEU Selection
  const [notEarningCredits, setNotEarning] = useState(false);
  const [creditWorkshop1, setCreditWorkshop1] = useState(false);
  const [creditWorkshop2, setCreditWorkshop2] = useState(false);
  const [creditCaptain, setCaptainCredits] = useState(false);
  const [creditBootCamp, setBootCampCredits] = useState(false);

  //Booleans for Event Registration
  const [bootCamp, setBootCamp] = useState(false);
  const [workshop1, setWorkshop1] = useState(false);
  const [workshop2, setWorkshop2] = useState(false);
  // const [orientation, setOrientation] = useState(false);
  // const [captainSummit, setCaptainSummit] = useState(true);

  // Booleans for Credit Selection
  const [notApplicable, setNotApplicable] = useState(false);
  const [bacbCredit, setBacbCredit] = useState(false);
  const [cpaCredit, setCpaCredit] = useState(false);
  const [pdHoursCredit, setPdHoursCredit] = useState(false);

  let user = new User();
  let transaction = new Transaction();
  var formData = new FormData();
  let payPortalLink;
  let formErrors: string[] = [];

  const handleValidation = () => {
    let formIsValid = true;
    formErrors = [];
    if (attendeeName.trim().length === 0 || !attendeeName) {
      formIsValid = false;
      formErrors.push("\n", "Attendee Name");
    }
    if (phoneNumber.trim().length === 0 || !phoneNumber) {
      formIsValid = false;
      formErrors.push("\n", "Phone Number");
    }
    if (address.trim().length === 0 || !address) {
      formIsValid = false;
      formErrors.push("\n", "Address");
    }
    if (city.trim().length === 0 || !city) {
      formIsValid = false;
      formErrors.push("\n", "City");
    }
    if (state.trim().length === 0 || !state) {
      formIsValid = false;
      formErrors.push("\n", "State");
    }
    if (zip.trim().length === 0 || !zip) {
      formIsValid = false;
      formErrors.push("\n", "Zip Code");
    }
    if (email.trim().length === 0 || !email) {
      formIsValid = false;
      formErrors.push("\n", "Email");
    }
    if (!agency) {
      formIsValid = false;
      formErrors.push("\n", "Job Alike Groups");
    }
    if (!attendeeType) {
      formIsValid = false;
      formErrors.push("\n", "Attendee Type");
    }
    if (!region) {
      formIsValid = false;
      formErrors.push("\n", "Region");
    }
    if (email != emailConfirm) {
      formIsValid = false;
      formErrors.push("\n", "Emails do not match");
    }
    if (!lunch) {
      formIsValid = false;
      formErrors.push("\n", "Lunch Selection");
    }
    if (ceuEvent.length == 0) {
      formIsValid = false;
      formErrors.push("\n", "Which events would you like to earn CEUs");
    }
    if (!role) {
      formIsValid = false;
      formErrors.push("\n", "Primary Role");
    }
    if (credit.length == 0) {
      formIsValid = false;
      formErrors.push("\n", "Which type of credits do you want to sign up for");
    }
    if (formErrors.length != 0) {
      alert(
        `The following fields are missing information:\n${formErrors
          .map((error) => error)
          .join("")} `
      );
    }
    return formIsValid;
  };

  const handleSubmit = async (event: any) => {
    event.preventDefault();
    if (handleValidation()) {
      //Assigning user values
      user.Name = attendeeName;
      user.Org = agency;
      user.Title = attendeeType;
      user.captainRegion = region;
      user.Phone = phoneNumber;
      user.Email = email;
      user.lunch = lunch;
      // user. = ceuEvent;
      user.primaryRole = role;
      user.bcbaCertificationNumber = certNumber;
      user.licenseType = license;
      user.licenseNumber = licenseNumber;
      user.isAttendingBootCamp = bootCamp;
      user.isAttendingWorkshop1 = workshop1;
      user.isAttendingWorkshop2 = workshop2;
      // user.isAttendingMentorOrientation = orientation;
      user.isAttendingCaptainSummit = true;
      user.notApplicableCredit = notApplicable;
      user.bacbCredit = bacbCredit;
      user.cpaCredit = cpaCredit;
      user.continuingPDHoursCredit = pdHoursCredit;
      user.isEarningCreditsAtBootCamp = creditBootCamp;
      user.isEarningCreditsAtCaptainSummit = creditCaptain;
      user.isEarningCreditsAtWorkshop1 = creditWorkshop1;
      user.isEarningCreditsAtWorkshop2 = creditWorkshop2;
      user.isNotEarningCreditsAtEvent = notEarningCredits;
      user.PaidAmount = eventPrice;
      //Assigning transaction values
      transaction.Name = attendeeName;
      transaction.Email = email;
      transaction.Phone = phoneNumber;
      transaction.Address = address;
      transaction.City = city;
      transaction.State = state;
      transaction.Zip = zip;
      transaction.Organization = agency;
      transaction.PaymentTotal = eventPrice;
      transaction.PayPortalLink = `${
        process.env.REACT_APP_PAYMENT_URL
      }payments/CAPTAIN%20Summit/${transaction.PaymentTotal * 100}/${
        process.env.REACT_APP_PAYMENT_ID
      }?name=${transaction.Name}&email=${transaction.Email}&user=`;
      transaction.Users.push(user);
      formData.append("transaction", JSON.stringify(transaction));
      await saveRegistration(formData, 20)
        .then((data) => {
          const transactionData: Transaction = data;
          const checkTransaction = data;
          if (
            checkTransaction.Message ===
            `${email} has already been used for a registered attendee.Please review and provide an alternate email address.Thank you.`
          ) {
            alert(
              `${email} has already been used for a registered attendee. Please provide an alternate email address.`
            );
          } else if(checkTransaction.Message !== undefined) {
            alert(`${email} has already been used for a registered attendee. Please provide an alternate email address.`)
          } else if(checkTransaction.Message === undefined) {
            window.location.href = transactionData.PayPortalLink;
          }
        })
        .catch((error) => {
          alert("Problem creating registration. Please try again.");
        });
    }
  };

  // Attendee Options Used for MultiSelect Dropdowns and Attendee Type
  const attendeeOptions = [
    "First Year Newly Nominated CAPTAIN Cadre",
    "Returning CAPTAIN Cadre",
    // "Cadre Mentor",
    "RIL/CAPTAIN Leadership Team Member",
  ];

  const ceuDropdownProps = {
    label: "Which events would you like to earn CEUs?",
    buttonLabel: "Select an event",
    defaultStateValue: [],
    checkType: "ceuCheck",
    attendee: attendeeType,
    selectState: ceuEvent,
    stateSetter: setCeuevent,
    checkedStates: [
      notEarningCredits,
      creditBootCamp,
      creditWorkshop1,
      creditWorkshop2,
      creditCaptain,
    ],
    checkedSetters: [
      setNotEarning,
      setBootCampCredits,
      setCreditWorkshop1,
      setCreditWorkshop2,
      setCaptainCredits,
    ],
    allAttendeeOptions: attendeeOptions,
  };

  const eventDropdownProps = {
    label: "Event Registration",
    labelSpan: "(Credit Card Only) - ",
    paymentWarning: "Payment non-refundable",
    buttonLabel: "Select event(s)",
    attendee: attendeeType,
    selectState: eventRegister,
    stateSetter: setRegistration,
    priceValue: eventPrice,
    setPrice: setPrice,
    // checkedStates: [bootCamp, workshop1, workshop2, orientation],
    checkedStates: [bootCamp, workshop1, workshop2],
    // checkedSetters: [setBootCamp, setWorkshop1, setWorkshop2, setOrientation],
    checkedSetters: [setBootCamp, setWorkshop1, setWorkshop2],
  };

  const creditDropdownProps = {
    state: credit,
    stateSetter: setCredit,
    checkedStates: [notApplicable, bacbCredit, cpaCredit, pdHoursCredit],
    checkedSetters: [
      setNotApplicable,
      setBacbCredit,
      setCpaCredit,
      setPdHoursCredit,
    ],
  };

  return (
    <>
      <div className="confBg">
        <ConfNavbar />
      </div>
      <Container>
        <h1 className="title d-flex justify-content-center mt-5 mb-4">
          ONLINE REGISTRATION
        </h1>
        <p className="subtext">
          Please enter the information below along with your credit card
          information to complete the registration process. If you have
          questions or need assistance with registration, contact us at
          <span id="contactEmail" className="mx-2">
            captain@marinschools.org
          </span>
        </p>
        <Form onSubmit={(event) => handleSubmit(event)}>
          <Row id="registrationBox">
            {/* Attendee Info Section */}
            <h3 className="registrationTitle mb-4">Attendee Information</h3>
            {/* Attendee Name */}
            <Col lg="6" className="mb-4">
              <RegisterInput
                label="Attendee Name"
                htmlLabel="attendeeName"
                placeholder="Enter attendee name"
                inputState={attendeeName}
                stateSetter={setName}
                requiredBoolean={true}
              />
            </Col>
            {/* Agency */}
            <Col lg="6" className="mb-4">
              <RegisterDropdown
                label="Job Alike Groups"
                defaultPlaceholder="Select an agency"
                labelState={agency}
                stateSetter={setAgency}
                dropdownOptions={[
                  "SELPA/School CDE",
                  "Family Support Agency",
                  "Higher Ed/UCEDD/LEND",
                  "Regional Center/DDS",
                ]}
              />
            </Col>
            {/* Attendee Type */}
            <Col lg="6" className="mb-4">
              <RegisterDropdown
                label="Attendee Type"
                defaultPlaceholder="Select an attendee type"
                labelState={attendeeType}
                stateSetter={setType}
                dropdownOptions={attendeeOptions}
              />
            </Col>
            {/* Captain Region */}
            <Col lg="6" className="mb-4">
              <RegisterDropdown
                label="CAPTAIN Region"
                labelClickable="Find Your Region Here"
                clickable={RegionPDF}
                defaultPlaceholder="Select a region"
                labelState={region}
                stateSetter={setRegion}
                dropdownOptions={[
                  "C.L.I.C. (CAPTAIN Local Interagency Collaborative)",
                  "CAPITAL CAPTAIN",
                  "CAPTAIN 007",
                  "CAPTAIN 101N",
                  "CAPTAIN 805",
                  "CAPTAIN BRIDGES",
                  "CAPTAIN HOOK (Hope-Opportunities-Outcome-Knowledge)",
                  "CAPTAIN KERN",
                  "CAPTAIN LA",
                  "CAPTAIN SAN DIEGO",
                  "CAPTAIN X",
                  "CAPTAINS OF THE EAST BAY",
                  "CAPTAINs of the New Wave",
                  "CENTRAL VALLEY CAPTAIN",
                  "NORTH STATE CAPTAIN",
                  "ORANGE COUNTY CAPTAINS",
                  "Super 14",
                ]}
              />
            </Col>
            {/* Phone */}
            <Col lg="6" className="mb-4">
              <RegisterInput
                label="Phone"
                htmlLabel="phoneNumber"
                placeholder="Enter your phone number"
                inputState={phoneNumber}
                stateSetter={setPhone}
                requiredBoolean={true}
              />
            </Col>
            {/* Address */}
            <Col lg="6" className="mb-4">
              <RegisterInput
                label="Address"
                htmlLabel="addressInput"
                placeholder="Enter your address"
                inputState={address}
                stateSetter={setAddress}
                requiredBoolean={true}
              />
            </Col>
            {/* City */}
            <Col lg="4" className="mb-4">
              <RegisterInput
                label="City"
                htmlLabel="cityInput"
                placeholder="Enter your city"
                inputState={city}
                stateSetter={setCity}
                requiredBoolean={true}
              />
            </Col>
            {/* State */}
            <Col lg="4" className="mb-4">
              <RegisterInput
                label="State"
                htmlLabel="stateInput"
                placeholder="Enter your state"
                inputState={state}
                stateSetter={setState}
                requiredBoolean={true}
              />
            </Col>
            {/* Zip Code */}
            <Col lg="4" className="mb-4">
              <RegisterInput
                label="Zip Code"
                htmlLabel="zipcodeNumber"
                placeholder="Enter your zip code"
                inputState={zip}
                stateSetter={setZip}
                requiredBoolean={true}
              />
            </Col>
            {/* Email */}
            <Col lg="6" className="mb-4">
              <RegisterInput
                label="Email"
                htmlLabel="emailAddress"
                placeholder="Enter your email address"
                inputState={email}
                stateSetter={setEmail}
                requiredBoolean={true}
                inputType="email"
              />
            </Col>
            {/* Confirm Email */}
            <Col lg="6" className="mb-4">
              <RegisterInput
                label="Confirm Email"
                htmlLabel="confirmEmail"
                placeholder="Re-enter your email address"
                inputState={emailConfirm}
                stateSetter={setConfirm}
                requiredBoolean={true}
                inputType="email"
              />
            </Col>
            {/* Event Info Selection */}
            <h3 className="registrationTitle mb-4 mt-4">
              Event Information
              <button onClick={handleModal} type="button" id="agendaAtGlance">
                Agenda at a glance
              </button>
            </h3>
            {/* Event Registration */}
            <Col lg="6" className="mb-4">
              <EventDropdown properties={eventDropdownProps} />
            </Col>
            {/* Lunch Selection */}
            <Col lg="6" className="mb-4">
              <RegisterDropdown
                label="Lunch Selection"
                labelSpan=" (November 3, 2022)"
                defaultPlaceholder="Select a meal"
                labelState={lunch}
                stateSetter={setLunch}
                dropdownOptions={[
                  "Oven roasted turkey tortilla wrap",
                  "Thai chicken tortilla wrap",
                  "Vegetarian and gluten free option",
                ]}
              />
            </Col>
            {/* CEUs Selection */}
            <Col lg="6" className="mb-4">
              <CeuDropdown properties={ceuDropdownProps} />
            </Col>
            {/* Primary Role Selection */}
            <Col lg="6" className="mb-4">
              <RegisterDropdown
                label="Primary Role"
                labelSpan="(For those signing up for Continuing Education Credits)"
                defaultPlaceholder="Select a role"
                labelState={role}
                stateSetter={setRole}
                dropdownOptions={[
                  "Not Applicable",
                  "Licensed Psychologist (Phd, PsyD)",
                  "MFT/LCSW",
                  "BCBA.BCABA",
                  "SLP (license # not required)",
                ]}
              />
            </Col>
            {/* Credit Selection */}
            <Col lg="6" className="mb-4">
              <CreditDropdown properties={creditDropdownProps} />
            </Col>
            {/* Certification Input */}
            <Col lg="6" className="mb-4">
              <RegisterInput
                label="BCBA certification number"
                labelSpan="(If applicable)"
                htmlLabel="certInput"
                placeholder="Enter BCBA certification number"
                inputState={certNumber}
                stateSetter={setCert}
                characterLimit={25}
                requiredBoolean={credit.includes("BACB (BCBAs, BCABAs)")}
              />
            </Col>
            {/* License Selection */}
            <Col lg="6" className="mb-4">
              <RegisterDropdown
                label="License type"
                labelSpan="(If applicable for CPA units)"
                defaultPlaceholder="Select a license type"
                labelState={license}
                stateSetter={setLicense}
                dropdownOptions={[
                  "LEP",
                  "LMFT",
                  "LCSW",
                  "Licensed Psychologist",
                ]}
                removeSelectionOption="(Select One)"
              />
            </Col>
            {/* License Input */}
            <Col lg="6" className="mb-4">
              <RegisterInput
                label="License Number"
                labelSpan="(If applicable for CPA units)"
                htmlLabel="licenseInput"
                placeholder="Enter license number"
                inputState={licenseNumber}
                stateSetter={setLnumber}
                characterLimit={25}
              />
            </Col>
            <Col id="registrationBtn">
              <h5 className="redText warningText">
                Please read before selecting the Complete Registration button
                and navigating to the payment portal.{" "}
              </h5>
              <p className="my-0 warningText">
                New Cadre - Ensure you are registered for the
                <strong className="mx-1 boldText">
                  {" "}
                  Boot Camp and the Summit.
                </strong>
              </p>
              <p className="my-0 mb-3 warningText">
                Returning Cadre - Ensure you are registered for the{" "}
                <strong className="boldText">Summit (required) </strong>
                 and either
                <strong className="boldText">
                  {" "}
                  Workshop 1 or Workshop 2 (optional).
                </strong>
              </p>
              <Button className="mb-5 mt-0 gradientBtn" type="submit">
                COMPLETE REGISTRATION
              </Button>
            </Col>
          </Row>
        </Form>
      </Container>
      {/* Agenda Modal */}
      <Modal
        show={show}
        onHide={handleModal}
        centered
        dialogClassName="agendaModal"
      >
        <Modal.Header closeButton>
          <h3 className="title">Summit Agendas</h3>
        </Modal.Header>
        <Modal.Body>
          <Row>
            <p className="registrationTitle mb-2">Day 1 Schedule</p>
            <ul className="mb-4">
              <li>
                <a
                  className="agendaGlanceBtn"
                  href={BootcampAgendaPDF}
                  target="_blank"
                  rel="noreferrer"
                >
                  Boot Camp
                </a>
              </li>
              <li>
                <a
                  className="agendaGlanceBtn"
                  href={WorkshopAgendaPDF}
                  target="_blank"
                  rel="noreferrer"
                >
                  Workshops
                </a>
              </li>
            </ul>
            <p className="registrationTitle mb-2">Day 2 Schedule</p>
            <ul>
              <li>
                <a
                  className="agendaGlanceBtn"
                  href={SummitAgendaPDF}
                  target="_blank"
                  rel="noreferrer"
                >
                  CAPTAIN Summit
                </a>
              </li>
            </ul>
          </Row>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default Register;
