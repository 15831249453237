import React, { Component } from "react";
import ConfNavbar from "./ConfNavbar";
import "../styles/venueStyles.css";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import { TitleUpdate } from "../services/TitleUpdater";
type Props = {};

const Venue: React.FC = (props: Props): JSX.Element => {
  TitleUpdate("CAPTAIN Summit - Venue");
  return (
    <>
      <div className="confBg">
        <ConfNavbar />
      </div>
      <Container id="venueScreen">
        <Row className="my-5">
          <Col sm="1"></Col>
          <Col
            lg="4"
            className="m-0 d-flex align-items-center justify-content-center"
            id="venueImgBox"
          >
            <img
              id="venueImg"
              src={require("../assets/Venue.png")}
              alt="Venue"
            />
          </Col>
          <Col lg="7" className="aboutTextBox" id="venueTextBox">
            <Row className="mx-auto">
              <p className="subtitle">CONFERENCE VENUE</p>
              <h1 className="title">OUR VENUE</h1>
              <p id="aboutDesc">
                The 2022 Captain Cadre Summit will take place at the Riverside
                Convention Center. Within steps of the Riverside Convention
                Center and downtown hotels, attendees will enjoy Main Street
                Riverside and its unique boutiques, restaurants & pubs, and
                specialty dessert shops.
                <br />
                <br />A room block for summit attendees is provided at a
                discounted price. Reserve your room early!
              </p>
              <Col className="venueBtnBox">
                <a
                  target="_blank"
                  rel="noreferrer"
                  href="https://riversidecvb.com/play/"
                  className="outlineBtn px-5 mx-0"
                >
                  LOCAL ATTRACTIONS
                </a>
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default Venue;
