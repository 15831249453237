import React, { Component, useState } from "react";
import { Link } from "react-router-dom";
import SpeakerCard from "./SpeakerCard";
import SessionCard from "./SessionCard";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import ConfNavbar from "./ConfNavbar";
import WelcomeImage from "../assets/WelcomePhoto.png";
import { ConferenceSpeakers, Session, Speaker } from "../services/testApiData"; //This is sample data meant for testing shouldn't be used in final version
import "../styles/landingStyles.css";
import "../styles/agendaStyles.css";
import '../styles/registerStyles.css';
import BootcampAgendaPDF from "../agendas/DAY 1 BOOT CAMP AGENDA.pdf";
import WorkshopAgendaPDF from "../agendas/DAY 1 WORKSHOPS AGENDA.pdf";
import SummitAgendaPDF from "../agendas/DAY 2 CAPTAIN 2022 SUMMIT AGENDA.pdf";
import { TitleUpdate } from "../services/TitleUpdater";

const Landing: React.FC<{ Speakers: Speaker[]; Sessions: Session[] }> = (
  props
): JSX.Element => {
  TitleUpdate("CAPTAIN Summit - Home");
  const [speakerData, setSpeakers] = useState(ConferenceSpeakers);

  const FeaturedSpeakers = (index: number, speaker: any) => {
    if (index < 8) {
      return (
        <SpeakerCard
          key={index}
          SpeakerImg={speaker.AvatarUrl}
          Bio={speaker.Bio}
          Name={speaker.Name}
          Title={speaker.Title}
        />
      );
    }
  };

  return (
    <>
      {/* Intro Section */}
      <Container fluid className="screen heroBg p-0">
        <ConfNavbar />
        <Container
          className="d-flex justify-content-center align-items-center"
          id="landingScreen"
        >
          <Row>
            <Col>
              <p className="landingSubtitle m-0 mb-4">
                November 2-3, 2022 • Riverside Convention Center
              </p>
              <p className="landingTitle m-0">CAPTAIN ANNUAL SUMMIT:</p>
              <p className="landingTitle">DATA IS DELICIOUS</p>
              <Row>
                {/* <Col lg="4" md="12" className="splashBtns">
                  <Link to="/Register">
                    <Button className="mx-2 my-3 gradientBtn">REGISTER</Button>
                  </Link>
                </Col> */}
                <Col lg="6" md="12">
                  <Link to="/Booking">
                    <Button className="mx-2 my-3 gradientBtn">RESERVE YOUR ROOM</Button>
                  </Link>
                </Col>
                <Col lg="6" md="12" className="splashBtns d-flex justify-content-center">
                  <a href="#welcomeScreen" className="mx-2 my-3 outlineBtn">
                    LEARN MORE
                  </a>
                </Col>
              </Row>
            </Col>
          </Row>
        </Container>
      </Container>
      {/* About Section */}
      <Container
        className="screen d-flex align-items-center"
        id="welcomeScreen"
      >
        <Row className="mt-5 d-flex align-items-center">
          <Col id="aboutImgBox" lg="12">
            <img id="aboutImg" src={WelcomeImage} alt="Public Speaker" />
          </Col>
          <Col className="aboutTextBox">
            <p className="subtitle mt-2 m-0">ABOUT THE CONFERENCE</p>
            <h1 className="title">
              WELCOME TO THE 2022 CAPTAIN CADRE ANNUAL SUMMIT
            </h1>
            <p id="aboutDesc">
              This Summit is an essential opportunity for invited CAPTAIN Cadre
              to convene for <strong> new learning</strong>,
              <strong> regional planning </strong> and
              <strong> sharing of ideas </strong>. Cadre will receive updates on
              EBPs, policy and “Hot Topics” in autism. CAPTAIN regional teams
              will develop their annual implementation plans and job alike
              groups will share expertise, resources, and experiences. Workshops
              will be offered to support ongoing learning in the area of EBPs
              and Autism. Attendance is required for ongoing active Cadre member
              status.
            </p>
          </Col>
        </Row>
      </Container>
      {/* Speakers Section */}
      <Container fluid className="screen" id="speakerScreen">
        <Container>
          <Row>
            <p className="subtitle d-flex justify-content-center align-items-center mt-5">
              EVENT CONFERENCE SPEAKERS
            </p>
            <h1 className="title d-flex justify-content-center align-items-center mb-5">
              FEATURED SPEAKERS
            </h1>
          </Row>
          <Row className="speakerRow">
            {props.Speakers.length === 0
              ? speakerData.map((speaker, index) =>
                  FeaturedSpeakers(index, speaker)
                )
              : props.Speakers.map((speaker, index) =>
                  FeaturedSpeakers(index, speaker)
                )}
          </Row>
          <div className="row d-flex justify-content-center align-items-center">
            <Link to="/Speakers" className="gradientBtn my-4 mb-5">
              SEE ALL SPEAKERS
            </Link>
          </div>
        </Container>
      </Container>
      {/* Sessions Section */}
      <Container fluid className="screen" id="agendaScreen">
        <Container>
          <Row>
            <p className="subtitle d-flex justify-content-center align-items-center mt-5">
              CONFERENCE AGENDA
            </p>
            <h1 className="title mb-5 d-flex justify-content-center align-items-center">
              SUMMIT AGENDA
            </h1>
          </Row>
          <Row>
            <Col></Col>
            <Col lg={7} className="sessionCard my-5">
            <p className="registrationTitle mb-2">Day 1 Schedule</p>
            <ul className="mb-4 mx-0 p-0">
              <li>
                <a
                  className="agendaGlanceBtn"
                  href={BootcampAgendaPDF}
                  target="_blank"
                  rel="noreferrer"
                >
                  Boot Camp
                </a>
              </li>
              <li>
                <a
                  className="agendaGlanceBtn"
                  href={WorkshopAgendaPDF}
                  target="_blank"
                  rel="noreferrer"
                >
                  Workshops
                </a>
              </li>
            </ul>
            <p className="registrationTitle mb-2">Day 2 Schedule</p>
            <ul>
              <li>
                <a
                  className="agendaGlanceBtn"
                  href={SummitAgendaPDF}
                  target="_blank"
                  rel="noreferrer"
                >
                  CAPTAIN Summit
                </a>
              </li>
            </ul>
            </Col>
            <Col></Col>
          </Row>
          {/* <div className="row d-flex justify-content-center align-items-center">
            <Link to="/Agenda" className="gradientBtn my-4 mb-5">
              SEE ALL SESSIONS
            </Link>
          </div> */}
        </Container>
      </Container>
    </>
  );
};

export default Landing;
